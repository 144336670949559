// src/App.js

import React from "react";
import "./App.css";
import WebSocketDisplay from "./WebSocketDisplay";

function App() {
  return (
    <div className="App">
      <h1>WebSocket Data Display</h1>
      <WebSocketDisplay />
    </div>
  );
}

export default App;
