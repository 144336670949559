// src/WebSocketDisplay.js

import React, { useEffect, useState } from "react";

const WebSocketDisplay = () => {
  const [webSocketData, setWebSocketData] = useState(null);

  useEffect(() => {
    // Create a WebSocket connection
    const ws = new WebSocket("wss://algo.aych.uk/algo-viewer/");

    // Event listener for WebSocket messages
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setWebSocketData(data);
    };

    // Clean up WebSocket connection on unmount
    return () => {
      ws.close();
    };
  }, []);

  // If no data has been received yet, display a loading message
  if (!webSocketData) {
    return <div>Loading WebSocket data...</div>;
  }

  const { trend, high, low, open_bar } = webSocketData;

  return (
    <div style={{ padding: "20px", fontFamily: "Arial" }}>
      <h2>SPY Trades Data</h2>
      <p>
        <strong>Trend:</strong> {trend}
      </p>
      <p>
        <strong>High:</strong> {high}
      </p>
      <p>
        <strong>Low:</strong> {low}
      </p>

      <h3>Open Bar Details</h3>
      <p>
        <strong>Date:</strong>
        {new Date(open_bar.date).toLocaleString("en-UK", {
          timeZone: "America/New_York",
        })}
      </p>
      <p>
        <strong>Open:</strong> {open_bar.open}
      </p>
      <p>
        <strong>High:</strong> {open_bar.high}
      </p>
      <p>
        <strong>Low:</strong> {open_bar.low}
      </p>
      <p>
        <strong>Close:</strong> {open_bar.close}
      </p>
      <p>
        <strong>Volume:</strong> {open_bar.volume}
      </p>
    </div>
  );
};

export default WebSocketDisplay;
